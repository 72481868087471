
<script>
/* eslint-disable */
import FooterComp from "../components/footerComp.vue";
export default {
  components: {
    FooterComp,
  },
};
</script>

<style>
</style>

<template>
  <div>
    <v-container class="mx-auto">
    
      <v-card color="blue-grey lighten-5" class="mx-auto ma-1" max-width="700" height="350" outlined>
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-overline mb-4">Febuary 28, 2025</div>
            <v-list-item-title class="text-h5 mb-1">
              3 Year Anniversary
            </v-list-item-title>
              <p>Happy 3rd Year Anniversary for Mayra's Trucking Services. Thank you for all the prayers and well wishes <br/></p>
          </v-list-item-content>
          <v-list-item-avatar tile size="200" color="grey">
            <v-img src="@/assets/3_year.jpg" height="200px"></v-img>
          </v-list-item-avatar>
        </v-list-item>
      </v-card>

      <v-card color="blue-grey lighten-5" class="mx-auto ma-1" max-width="700" height="350" outlined>
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-overline mb-4">December 25th, 2024</div>
            <v-list-item-title class="text-h5 mb-1">
              Seasons Greetings
            </v-list-item-title>
              <p>Happy Holidays from the Herrera family <br/></p>
          </v-list-item-content>
          <v-list-item-avatar tile size="200" color="grey">
            <v-img src="@/assets/2024_holiday.jpg" height="200px"></v-img>
          </v-list-item-avatar>
        </v-list-item>
      </v-card>
      <v-card color="blue-grey lighten-5" class="mx-auto ma-1" max-width="700" height="350" outlined>
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-overline mb-4">Febuary 28, 2024</div>
            <v-list-item-title class="text-h5 mb-1">
              2 Year Anniversary
            </v-list-item-title>
              <p>Happy second year Anniversary Mayra's Trucking Services! <br/></p>
          </v-list-item-content>
          <v-list-item-avatar tile size="200" color="grey">
            <v-img src="@/assets/TwoYear.jpg" height="200px"></v-img>
          </v-list-item-avatar>
        </v-list-item>
      </v-card>

      <v-card color="blue-grey lighten-5" class="mx-auto ma-1" max-width="700" height="350" outlined>
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-overline mb-4">Febuary 28, 2023</div>
            <v-list-item-title class="text-h5 mb-1">
              1 Year Anniversary
            </v-list-item-title>
              <p>Thanks to everyone who came out to support us! We appreciate all of the support each of you have given us. You are all special to me. <br/> #soulTribe</p>
          </v-list-item-content>
          <v-list-item-avatar tile size="200" color="grey">
            <v-img src="@/assets/1year.jpg" height="200px"></v-img>
          </v-list-item-avatar>
        </v-list-item>
      </v-card>
      
      <v-card color="blue-grey lighten-5" class="mx-auto ma-1" max-width="700" height="350" outlined>
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-overline mb-4">February 7, 2023</div>
            <v-list-item-title class="text-h5 mb-1">
              100th customer
            </v-list-item-title>
              <p>We have just reached a huge milestone. I couldn't have done it with the support of my loving family. Lets go MTS!</p>
          </v-list-item-content>
          <v-list-item-avatar tile size="200" color="grey">
            <v-img src="@/assets/verification.jpg" height="200px"></v-img>
          </v-list-item-avatar>
        </v-list-item>
      </v-card>
    </v-container>
    <footer-comp></footer-comp>
  </div>
</template>